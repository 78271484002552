import { TimeValue } from '@common/type/DateType';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ms from 'ms';

dayjs.extend(buddhistEra);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

/**
 * @deprecated use dateFormat instead
 * */
export const convertDateFormatToString = (date: Date): string => {
  dayjs.extend(utc);
  const utcPlus7Date = dayjs(date).utcOffset(7);
  const stringDate = utcPlus7Date.format('DD/MM/YYYY');
  return stringDate;
};

export const parseDate = (dateString: string, format: string, strict?: boolean) => {
  dayjs.extend(buddhistEra);
  dayjs.extend(customParseFormat);
  return dayjs(dateString, format, strict);
};

export default dayjs;

/**
 * It takes a date and a format and returns a formatted date.
 * @param date - The date you want to format.
 * @param [format=DD/MM/YYYY HH:mm:ss] - The format you want to use to display the date.
 */
export const dateFormat = (date: Date | string, format = 'DD/MM/YYYY HH:mm:ss') => dayjs(date).format(format);
/**
 * @param {string} timeValue is time or day or year. Ex. 1s, 1m, 1h, 1d, 1y.
 * @description Require one  suffix:
 *  *  s is Seconds
 *  *  m is Minutes
 *  *  h is Hours
 *  *  d is Days
 *  *  y is Years
 * @returns
 */
export const convertTimeToSeconds = (timeValue: TimeValue) => ms(timeValue) / 1000;

export const convertDuration = (duration: number) => ({
  toHour: () => Math.floor(duration / 3600),
  toMinute: () => Math.floor((duration / 60) % 60),
});
