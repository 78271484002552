import Cookies from 'universal-cookie';

import { convertTimeToSeconds } from '@helpers/dateUtils';

export const setAffiliateCookie = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const affiliateToken = urlParams.get('a');

  if (!affiliateToken) return;
  
  const currentUrl = window.location.href;
  const { referrer } = document;

  const path = '/'
  const maxAge = convertTimeToSeconds('400d')
  
  const cookies = new Cookies();
  cookies.set('skilllane_a', affiliateToken, { path, maxAge });
  cookies.set('skilllane_ads', currentUrl, { path, maxAge });
  cookies.set('skilllane_ref', referrer, { path, maxAge });
};